import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"
import TextSection from "../components/textSection"
import HighlightsCards from "../components/highlightsCards"
import Dots from "../components/dots"

const IndexPage = (props) => {
	const data = useStaticQuery(graphql`
		query Home {
			dnhCms {
				pages(where: {pageTitle: "Home"}) {
					id
					pageTitle
					heading
					copy
					textSection {
						heading
						copy
					}
					images {
						asset {
							url
						}
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.pages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}>
				<SEO title={data.dnhCms.pages[0].pageTitle} />
				<TextSection sectionClass="about"
							headingType="primary"
							heading={data.dnhCms.pages[0].heading}
							copy={data.dnhCms.pages[0].copy}
							textSize="big"
							textColor="blue"
							bgColor="grayBg">
					<div className="circle-set">
						<div className="circle blue stroke-18 size-50"></div>
						<div className="circle blue size-20"></div>
						<div className="circle blue size-6"></div>
					</div>
				</TextSection>
				<HighlightsCards />
				<TextSection sectionClass="city"
							heading={data.dnhCms.pages[0].textSection.heading}
							copy={data.dnhCms.pages[0].textSection.copy}
							textColor="white"
							textSize="big"
							bgImage={data.dnhCms.pages[0].images[0].asset.url}
							overlay="blueOverlay">
					<div className="circle-set">
						<div className="circle white stroke-18 size-50"></div>
						<div className="circle white size-20"></div>
						<div className="circle white size-6"></div>
					</div>
					<div className="dots white">
						<Dots />
					</div>
				</TextSection>
				<div className="dots contact" style={{backgroundImage: `url(${data.dnhCms.pages[0].images[1].asset.url})`}}></div>
			</Layout>
		</>
	)
}

IndexPage.propTypes = {
	location: PropTypes.object
}

export default IndexPage
