import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import CurlyLineDot from "./curlyLineDot"
import GraphImg from "graphcms-image"

const HighlightsCards = () => (
	<StaticQuery
		query={graphql`
			query {
				dnhCms {
					highlightsSections(where: {status: PUBLISHED}) {
						upHeading
						heading
						copy
					}
					highlightsCardses(where: {status: PUBLISHED}) {
						id
						image {
							fileName
							url
							handle
							width
							height
							fileName
						}
						upHeading
						heading
						subPage{
							pageTitle
							page {
								pageTitle
							}
						}
					}
				}
			}
		`}
		render={data => (
			<div className="highlights-section container">
				<div className="highlights-text-wrap">
					<div className="left-side">
						<h3>{data.dnhCms.highlightsSections[0].upHeading}</h3>
						<h2>{data.dnhCms.highlightsSections[0].heading}</h2>
					</div>
					<div className="right-side">
						<p>{data.dnhCms.highlightsSections[0].copy}</p>
					</div>
				</div>
				<div className="highlights-cards-wrap">
					{data.dnhCms.highlightsCardses.map(card => (
						<div key={card.id} className="card">
							<Link
								to={`/${card.subPage.page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${card.subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}>
								<div className="card-img-wrapper">
									<GraphImg
										image={card.image}
										fit="scale"
										backgroundColor={true}
										withWebp={true}
										alt={card.image.fileName} />
								</div>
							</Link>
							<div className="text-box">
								<h3>{card.upHeading}</h3>
								<h2>{card.heading}</h2>
								<Link
									to={`/${card.subPage.page.pageTitle.replace(/\s+/g, '-').toLowerCase()}/${card.subPage.pageTitle.replace(/\s+/g, '-').toLowerCase()}`}>
									{card.subPage.pageTitle}
									<i className="fas fa-arrow-right"></i>
								</Link>
							</div>
						</div>
					))}
				</div>
				<div className="circle-line-set">
					<CurlyLineDot/>
				</div>
			</div>
		)}
	/>
)
export default HighlightsCards
